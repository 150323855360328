/* eslint-disable no-console */

import {
    fetchQuery
} from 'Util/Request';

import { EVENT_GTM_PURCHASE } from '../component/GoogleTagManager/GoogleTagManager.events';
import GtmPurchaseQuery from '../query/GtmPurchase.query';

const getPurchase = (orderID, event) => {
    const query = GtmPurchaseQuery.getGTMPurchase(orderID);

    fetchQuery(query).then(({ purchase }) => {
        event(EVENT_GTM_PURCHASE, { ...purchase, orderID });
    });
};

const componentDidMount = (args, callback, instance) => {
    const { orderID, event } = instance.props;

    if (orderID) {
        getPurchase(orderID, event);
    }

    callback.apply(instance, args);
};

export default {
    'Component/CheckoutSuccess/Component': {
        'member-function': {
            componentDidMount
        }
    }
};
